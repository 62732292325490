import * as React from "react"
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Menu from "../components/menu"
import "../css/main.scss"
import StandardHeadContent from "../components/standardHeadContent";
import Cookies from "universal-cookie";
import Axios from "axios";

const GiftsPage = () => {
    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }

    useEffect(() => {
        Axios.get("/log.php?page=gifts_"+lang);
    },[])

    return (
        <main>
            <Helmet>
                <title>Nina & Knut - 11. juli 2022</title>
                <StandardHeadContent/>
            </Helmet>

            <Menu lang={lang} changeLang={changeLang}/>

            <div id="duogram"></div>

            {lang != "en" &&
                <>
                    <h1>Kontodetaljer for evt. gave</h1>

                    <p>
                        Som nevnt på gavesiden så forventer vi ikke noen gaver fra våre gjester.
                        Hvis dere på tross av dette ønsker å gi et lite bidrag til vårt pågående oppussingsprosjekt
                        i huset vårt i Drammen settes dette selvfølgelig veldig stor pris på. Kontodetaljer følger:
                    </p>

                    <p>
                        <strong>Norsk kontonr:</strong> 1204 39 80392<br/><br/>
                        <strong>IBAN:</strong> NO7612043980392<br/>
                        <strong>BIC/SWIFT:</strong> DNBANOKKXXX<br/>
                        <strong>BANK:</strong> DNB BANK ASA (NORWAY)<br/>
                        <strong>BANKENS ADDRESSE:</strong> Dronning Eufemias gate 30, 0191 Oslo<br/><br/>
                        <strong>KONTOEIER:</strong> Knut Alexander Reistad<br/>
                        <strong>KONTOEIERS ADRESSE:</strong> Inkognitogaten 30, 0256 Oslo<br/>
                    </p>
                </>
            }
            {lang == "en" &&
                <>
                    <h1>Account details for gift</h1>

                    <p>
                        As mentioned on the gifts page we do not expect any gifts from our guests. If you despite this
                        would like to make a little financial contribution to our ongoing renovation project in our house in Drammen
                        that would be appreciated. Account details follow:
                    </p>

                    <p>
                        <strong>IBAN:</strong> NO7612043980392<br/>
                        <strong>BIC/SWIFT:</strong> DNBANOKKXXX<br/>
                        <strong>BANK:</strong> DNB BANK ASA (NORWAY)<br/>
                        <strong>BANK ADDRESS:</strong> Dronning Eufemias gate 30, 0191 Oslo<br/><br/>
                        <strong>ACCOUNT HOLDER:</strong> Knut Alexander Reistad<br/>
                        <strong>ACCOUNT HOLDER ADDRESS:</strong> Inkognitogaten 30, 0256 Oslo<br/>
                    </p>
                </>
            }

        </main>
    )
}

export default GiftsPage
